import React, { useState  , useEffect} from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const history = useNavigate();

  useEffect(() => {

    if(localStorage.getItem('userData')  == 'yes'){
      history('/admin/blog/add')
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {

      if(email === 'developerdalpat@gmail.com' && password == 'Ravindra@810') {
        localStorage.setItem("userData", 'yes');

        history('/admin/blog/add'); 

      }else{
        alert('Wrong credentials')
      }
      // Make API call here with email and password
   
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred");
    }

  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={6} lg={4} className="mx-auto">
          <Card className="shadow-lg">
            <Card.Body>
              <h2 className="text-center mb-4">Login</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label className="">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                >
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
