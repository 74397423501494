import React  , {useState ,useEffect } from 'react';
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
import axios from 'axios';
import { useParams, useRoutes } from 'react-router-dom';
const staticData = {
  HeaderImage: 'https://images.ctfassets.net/hrltx12pl8hq/28ECAQiPJZ78hxatLTa7Ts/2f695d869736ae3b0de3e56ceaca3958/free-nature-images.jpg?fit=fill&w=1200&h=630',
  Tags: 'Tag1 Tag2 Tag3',
  Title: 'Image processing tech',
  Author: 'Author Name',
  Content: `
    <p>Developed a web application with a dynamic backend and interactive frontend, offering users the ability to select templates, customize designs, and download their creations.</p>
    <p>More details about the project...</p>
  `
};



const comments = [
  { id: 1, author: 'User1', text: 'Great article!' },
  { id: 2, author: 'User2', text: 'Very informative, thanks!' }
];


function BlogInner() {

const { id } = useParams()



const [blogDetails, setBlogDetails] = useState([]);
  const [isBlogFetched, setisBlogFetched] = useState();

  const getAllBlogs = () => {
    try {
      setisBlogFetched(false);
      axios
        .post("https://admin.dalpatdevelopment.xyz/api/blog/get-blog-details" ,  {blog_id : id})
        .then((res) => {
          const { status, message, blogs } = res.data;

          if (status === true) {
              setBlogDetails(blogs);
              setisBlogFetched(true);
            
          } else {
            setisBlogFetched(message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAllBlogs();
  }, []);



  return (
    
    <Container fluid className="d-flex text-white justify-content-center py-4 mt-5">
      <Row className="w-100">
        <Col md={2} className=" d-md-block">
          {/* Free area for AdSense */}
        </Col>
        {isBlogFetched === true ? (
          
        <Col md={8}>
          <div className=" rounded shadow-sm p-4">
            <Image src={blogDetails.image} fluid rounded />
            <div className="text-start my-4">
              {/* {staticData.Tags.split(' ').map(tag => (
                <span
                  key={tag}
                  className="badge bg-primary text-uppercase mx-1"
                >
                  {tag}
                </span>
              ))} */}
              <h1 className="mt-3 text-center ">{blogDetails.heading}</h1>
              <BsThreeDots size={50} className="my-2  m-auto" />
              <div dangerouslySetInnerHTML={{ __html: blogDetails.long_description }} />
              <div className="my-4">
                <BsThreeDots size={30} className="mb-2" />
                <p className="text-white ">Author</p>

                <strong className="purple">{blogDetails.author}</strong>

              </div>
            </div>
            <div className="mt-4">
              <h2>Comments</h2>
              {/* <div className="mb-4">
                {comments.map(comment => (
                  <div key={comment.id} className="border-bottom mb-3 pb-3">
                    <p className="">{comment.author}</p>
                    <p>{comment.text}</p>
                  </div>
                ))}
              </div> */}
              <h3>Leave a comment</h3>
              <Form>
                <Form.Group controlId="commentTextarea">
                  <Form.Control as="textarea" rows={3} placeholder="Write your comment here..." />
                </Form.Group>
                <div className='d-flex justify-content-end'>

                <Button variant="primary" className="mt-2 text-end" >Submit</Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
        
      ) : null
    }
        <Col md={2} className=" d-md-block">
          {/* Free area for AdSense */}
        </Col>
      </Row>
      <aside className=" d-lg-block ml-4">
        {/* Table of Contents Component or other content */}
      </aside>
    </Container>
  );
}

export default BlogInner;
