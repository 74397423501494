import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import BlogCard from "./BlogCard";
import Particle from "../Particle";
import axios from "axios";
// import shopping from "../../Assets/Projects/shopping.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import learnwell from "../../Assets/Projects/learnwell.png";
// import Pixvix from "../../Assets/Projects/pixvix.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import Winzz from "../../Assets/Projects/winzz.png";

function Projects() {
  const [blogItems, setBlogItems] = useState([]);
  const [isBlogFetched, setisBlogFetched] = useState();

  const getAllBlogs = () => {
    try {
      setisBlogFetched(false);
      axios
        .post("https://admin.dalpatdevelopment.xyz/api/blog/get-all-blogs")
        .then((res) => {
          const { status, message, blogs } = res.data;

          if (status === true) {
            if (blogs.length > 0) {
              setBlogItems(blogs);
              setisBlogFetched(true);
            } else {
              setisBlogFetched(message);
            }
          } else {
            setisBlogFetched(message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAllBlogs();
  }, []);

  console.log(blogItems);

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Tech<strong className="purple"> Blogs </strong>
        </h1>

        {isBlogFetched === false ? (
          <Spinner animation="border" color="#00BFFF" height={100} width={100} /> // Render the spinner
        ) : null}
        {isBlogFetched === true ? (
          <Row style={{ justifyContent: "", paddingBottom: "10px" }}>
            {blogItems.map((item, index) => (
              <Col md={4} className="project-card" key={index}>
                <BlogCard
                  imgPath={item.image}
                  isBlog={true}
                  title={item.heading}
                  description={item.short_description}
                  blogLink={item.id}
                  author={item.author}
                  timeToRead={item.time_to_read}
                />
              </Col>
            ))}
          </Row>
        ) : null
        }

        {isBlogFetched != true && isBlogFetched !== false ? (
          <div className="fs-1 text-white">No Blog found</div>
        ) : null}
      </Container>
    </Container>
  );
}

export default Projects;
