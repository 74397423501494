import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite, CgUser  , CgSandClock} from "react-icons/cg";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      {props.imgPath && (
        <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      )}
      <Card.Body>
        <Card.Title className="text-start  ">
          <a href="#" className="text-reset fs-3 text-decoration-none ">
            {props.title}
          </a>
        </Card.Title>

        <Card.Text className="d-flex justify-content-between">
          <div className="d-flex align-content-center align-items-center ">
            <span className="  rounded">
              <CgUser></CgUser>
              {props.author ?? 'Dalpat singh'}
            </span>
          </div>

          <div className="d-flex align-content-center align-items-center ">
            <span className="rounded">
              <CgSandClock></CgSandClock>
             {props.timeToRead ?? '5 mins '}
            </span>
          </div>
        </Card.Text>

        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}.....
        </Card.Text>

        {"\n"}
        {"\n"}
        {props.isBlog && (
          <Button
            className="text-start"
            variant="primary"
            href={'/blog/' + props.blogLink}
             target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Read More"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
