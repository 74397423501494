import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            As a seasoned{" "}
            <span className="purple">
              MERN Stack Developer, Full Stack Developer, and PHP Developer{" "}
            </span>{" "}
            based in Jaipur, I specialize in crafting innovative web and mobile
            applications that drive business success. With a strong background
            in <span className="purple"> React, Laravel, and Node.js,</span> I
            excel in developing scalable, efficient, and user-friendly solutions
            that meet the unique needs of my clients.
          </p>
          <p style={{ textAlign: "justify" }}>
            With expertise in React Native, I'm well-versed in creating seamless
            mobile experiences that captivate users. As a Full Stack Web
            Developer and Mobile App Developer, I'm proficient in handling
            projects from concept to launch, ensuring timely delivery and
            exceptional quality. My proficiency in JavaScript, Node.js,
            Express.js, and MongoDB enables me to tackle complex backend
            development projects with ease. I'm also skilled in MySQL and
            Backend Development, ensuring that my projects are robust, secure,
            and scalable.
          </p>

          <p style={{ textAlign: "justify" }}>
            As a Frontend Developer, I'm passionate about creating visually
            stunning and interactive user interfaces using React, Angular, and
            Vue.js. My expertise in Web Development Services and Mobile App
            Development Services allows me to offer comprehensive solutions that
            cater to diverse business needs. Whether you need a Custom Web
            Solution or a Full Stack Web Developer in Jaipur, I'm the perfect
            partner for your project.
          </p>
          <p style={{ textAlign: "justify" }}>
            With a strong foundation in Software Engineering, I'm a Freelance
            Developer and Professional Developer dedicated to delivering
            high-quality, custom web solutions that meet the unique requirements
            of my clients. My expertise in Laravel Development and Laravel
            Development in Jaipur enables me to craft robust, scalable, and
            secure web applications that drive business growth.
          </p>
          <p style={{ textAlign: "justify" }}>
            As a React Developer in Jaipur, I'm well-versed in creating reusable
            UI components, managing state changes, and optimizing application
            performance. My proficiency in React Native Development and React
            Native Development in Jaipur allows me to develop cross-platform
            mobile applications that provide a seamless user experience.
          </p>
          <p style={{ textAlign: "justify" }}>
            In addition to my technical expertise, I possess excellent
            communication and project management skills, ensuring that my
            clients are always informed and involved throughout the development
            process. My commitment to delivering exceptional results has earned
            me a reputation as a reliable and skilled Full Stack Web Developer
            in Jaipur and Mobile App Developer in Jaipur.
          </p>
          <h2 className="purple">My Expertise</h2>

          <ul style={{ textAlign: "left" }}>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
                MERN Stack Development :{" "}
              </span>
              I'm proficient in developing scalable, efficient, and
              user-friendly solutions using MongoDB, Express.js, React, and
              Node.js.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Full Stack Development :{" "}
              </span>
              I excel in handling projects from concept to launch, ensuring timely delivery and exceptional quality.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              PHP Development :{" "}
              </span>
              I'm skilled in developing robust, scalable, and secure web applications using PHP.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              React Development :{" "}
              </span>
              I'm well-versed in creating reusable UI components, managing state changes, and optimizing application performance.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Laravel Development :{" "}
              </span>
              I'm proficient in crafting robust, scalable, and secure web applications using Laravel.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Node.js Development :{" "}
              </span>
              I'm skilled in developing fast, scalable, and secure server-side applications using Node.js.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Express.js Development :{" "}
              </span>
              I'm proficient in building fast, scalable, and secure web applications using Express.js.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              MongoDB Development :{" "}
              </span>
              I'm skilled in designing and implementing scalable, efficient, and secure database solutions using MongoDB.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              MySQL Development :{" "}
              </span>
              I'm proficient in designing and implementing scalable, efficient, and secure database solutions using MySQL.
            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Backend Development :{" "}
              </span>
              I'm skilled in developing robust, scalable, and secure backend solutions that meet the unique needs of my clients.
              </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Frontend Development :{" "}
              </span>
              I'm passionate about creating visually stunning and interactive user interfaces using React, Angular, and Vue.js.            </li>
            <li>
              <span className="purple" style={{ fontWeight: 600 }}>
              Web Development Services :{" "}
              </span>
              I offer comprehensive web development services that cater to diverse business needs.            </li>
          </ul>
         
          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to create innovations that leave an indelible mark on the
            world, for in the pursuit of meaningful change, we forge a legacy
            that echoes through the corridors of progress.!"{" "}
          </p>
          <footer className="blockquote-footer">Dalpat Rathore</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
