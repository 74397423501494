import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CodeBlock } from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useNavigate } from "react-router-dom";
function AddBlog() {
  const [formData, setFormData] = useState({
    timeToRead: "",
    heading: "",
    image: "",
    content: "",
    shortDescription: "",
    description: "",
    keywords: "",
    authorName: "",
  });

  const navigate = useNavigate()

  const [blogCountTotal, setBlogCount] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({ ...formData, content: data });
  };

  const getBlogCounts = ()=>{
    axios
    .post("https://admin.dalpatdevelopment.xyz/api/blog/get-blog-count")
    .then((res) => {
      const { status, blogCount } = res.data;

      if (status == true) {
        setBlogCount(blogCount);
      }
    }).catch((err)=>{
      console.error(err);
    });
  }

  useEffect(() => {

    if(localStorage.getItem('userData') != 'yes'){
      navigate('/admin/login')
    }
    getBlogCounts()
  }, []);

  const handleImageChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, image: files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    axios
      .post(
        "https://admin.dalpatdevelopment.xyz/api/blog/add",
        { formData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        getBlogCounts()

        const {status , message} = res.data
        if(status === true){
          alert(message)
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="mt-5">
      <div className="text-white">
       Total blogs {blogCountTotal}
      </div>
      <Row className="justify-content-center text-white">
        <Col md={8}>
          <h2 className="text-center  text-white mb-4">Add New Blog</h2>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group controlId="timeToRead">
              <Form.Label>Time to Read</Form.Label>
              <Form.Control
                type="text"
                name="timeToRead"
                value={formData.timeToRead}
                onChange={handleChange}
                placeholder="Enter time to read"
              />
            </Form.Group>
            <Form.Group controlId="heading">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
                placeholder="Enter heading"
              />
            </Form.Group>

            <Form.Group controlId="heading">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter description"
              />
            </Form.Group>

            <Form.Group controlId="heading">
              <Form.Label>Keywords</Form.Label>
              <Form.Control
                type="text"
                name="keywords"
                value={formData.keywords}
                onChange={handleChange}
                placeholder="Enter keywords"
              />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleImageChange}
                placeholder="Enter image URL"
              />
            </Form.Group>

              <Form.Label>Content</Form.Label>
            <Form.Group controlId="content" className="text-black">

              <CKEditor
                editor={Editor}
                className="text-black"
                data="<p>Hello from CKEditor&nbsp;5!</p>"
                // plugins={CodeBlock}
                name="content"
                config={{
                  toolbar: [
                    "heading",
                    "codeBlock",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "ckfinder",
                    "|",
                    "imageTextAlternative",
                    "imageUpload",
                    "imageStyle:full",
                    "imageStyle:side",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "|",
                    "undo",
                    "redo",
                  ],
                }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={handleEditorChange}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </Form.Group>
            <Form.Group controlId="shortDescription">
              <Form.Label>Short Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="shortDescription"
                value={formData.shortDescription}
                onChange={handleChange}
                placeholder="Enter short description"
              />
            </Form.Group>
            <Form.Group controlId="authorName">
              <Form.Label>Author Name</Form.Label>
              <Form.Control
                type="text"
                name="authorName"
                value={formData.authorName}
                onChange={handleChange}
                placeholder="Enter author name"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default AddBlog;
