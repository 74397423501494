import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import shopping from "../../Assets/Projects/shopping.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import learnwell from "../../Assets/Projects/learnwell.png";
// import Pixvix from "../../Assets/Projects/pixvix.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import Winzz from "../../Assets/Projects/winzz.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently <b className="purple">( All apps are made in VueJs, ReactJs , React native , Node js and  Laravel )</b>.
        </p>
        <p style={{ color: "white" }}>
          Projects are made for clients and all are big as they took almost 1 or 2 months in full development.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              //imgPath={Pixvix}
              isBlog={false}
              title="Image processing tech"
              description="Developed a web application with a dynamic backend and interactive frontend, offering users the ability to select templates, customize designs, and download their creations. Admins possess extensive capabilities to create, manage, and update templates, alongside additional functionalities for efficient site management. This platform provides a user-friendly interface for creative design and streamlined administrative control. It empowers users to unleash their creativity while ensuring smooth navigation and seamless functionality. With intuitive tools and comprehensive features, the application enhances user experience and enables efficient management of design projects, catering to both novice users and experienced professionals alike.              ."
              // ghLink=""
             // demoLink="https://pixvix.xyz/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
            //  imgPath={Winzz}
              isBlog={false}
              title="Product selling management"
              description="Developed a comprehensive sales management and distribution platform catering to a cola company's needs. The system equips RSMs, ASMs, and Sales Executives with tools to strategize, add retailers/distributors, conduct tours, and analyze reports. Distributors and retailers access their own app for ordering and offer viewing. The admin panel ensures thorough oversight by monitoring team activities, reviewing reports, managing orders and plans, and administering users. This solution revolutionizes operational efficiency, providing real-time insights and optimizing the sales hierarchy for sustained success.It integrates seamlessly into the company's workflow, fostering collaboration and driving growth. With its user-friendly interface and robust features."
             // ghLink="https://github.com/dalpat49/Bits-0f-C0de"
              //demoLink="https://users.winzz.in/"
            />
          </Col>

         

          <Col md={4} className="project-card">
            <ProjectCard
             // imgPath={shopping}
              isBlog={false}
              title="FashionHub"
              description="Developed FashionHub, an eCommerce website dedicated to clothing, featuring an intuitive user interface and a robust admin panel. Customers can browse a diverse product catalog, use advanced search and filters, manage shopping carts, and securely checkout. They can also create accounts, track orders, and leave reviews. The admin panel allows for comprehensive product, order, and inventory management, along with user account administration and access to detailed sales reports and analytics. FashionHub offers a seamless shopping experience for customers and efficient management capabilities for administrators, ensuring smooth operations and high customer satisfaction.It provides all the e-commerce operations with a perfect admin panel."
              // ghLink=""
             // demoLink="https://g-shoppers.onrender.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
            //  imgPath={suicide}
              isBlog={false}
              title="RideHub"
              description="Developed RideHub, a comprehensive platform for an aggregator company specializing in car and bike rentals. The platform features a partner portal where rental partners can create stations, set prices, and list their vehicle models. Admins can review and approve these listings, manage vehicle statuses, and make vehicles available for rent. The user-facing app allows customers to select their city and area, view available vehicles, and choose rental options based on monthly, daily, or hourly rates. RideHub offers a seamless and efficient experience for both rental partners and users, ensuring easy access to a wide range of vehicles and flexible rental options."
              //ghLink="https://github.com/dalpat49/AI_For_Social_Good"
             // demoLink="https://ezeego.in" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
             // imgPath={learnwell}  
              isBlog={false}
              title="SalesTrack Pro"
              description="Developed SalesTrack Pro, a comprehensive application for company sales personnel and HR management. Sales personnel can log in to check in/out, schedule meetings and follow-ups, claim expenses, view company events and announcements, apply for leave, and have their live location tracked. They can also access performance reports. The HR admin panel allows HR to generate salary slips, monitor employee attendance and leave, and manage various HR tasks. This application enhances productivity and provides efficient oversight of sales operations and HR processes."
              // ghLink="https://github.com/dalpat49/Editor.io"
              //demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>


       

          <Col md={4} className="project-card">
            <ProjectCard
             // imgPath={emotion}
              isBlog={false}
              title="ExpenseTrackr"
              description="Introducing ExpenseTrackr, a sophisticated expense tracking application developed with React Native. This innovative tool enables users to effortlessly monitor their spending, generate comprehensive reports, and easily split payments with friends. With intuitive features for adding expenses and categorizing transactions, users can efficiently manage their financial data. Additionally, the app offers robust authentication functionality, ensuring user privacy and security through seamless login and logout processes. ExpenseTrackr provides a streamlined solution for individuals seeking to gain control over their expenses and simplify payment management, all within a sleek and user-friendly mobile interface."
              //ghLink="https://github.com/dalpat49/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
