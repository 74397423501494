import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>

            <h2 style={{ fontSize: "", textAlign: "left" }}>
              <span className="purple"> About Me </span>
            </h2>

            <p className="home-about-body">
              As a passionate programmer, I've had the privilege of diving into
              the world of web development and discovering my true calling. With
              a solid foundation in classic technologies like JavaScript, React,
              Vue, Node.js, Tailwind, Material UI, Bootstrap, PHP, Laravel, and
              Laravel Backpack, I'm always excited to explore new frontiers in
              web development.
            </p>
            <h2 style={{ textAlign: "left" }} className="purple">
              Areas of Interest
            </h2>
            <p className="home-about-body">
              My primary focus lies in building innovative web technologies and
              applications that push the boundaries of what's possible. Whenever
              possible, I enjoy applying my skills to develop products using
              Angular and modern JavaScript libraries and frameworks like
              Nuxt.js and Next.js. Additionally, I'm fascinated by mobile
              application development technologies like Flutter, which enable me
              to create seamless user experiences across multiple platforms.
            </p>
            <h2 style={{ textAlign: "left" }} className="purple">
              Expertise
            </h2>

            <ul style={{ textAlign: "left" }}>
              <li>
                <span className="purple" style={{ fontWeight: 600 }}>
                  Front-end development :{" "}
                </span>
                JavaScript, React, Vue, Angular, Nuxt.js, Next.js
              </li>
              <li>
                <span className="purple" style={{ fontWeight: 600 }}>
                  Back-end development :{" "}
                </span>
                Node.js, PHP, Laravel, Laravel Backpack
              </li>

              <li>
                <span className="purple" style={{ fontWeight: 600 }}>
                  UI/UX :{" "}
                </span>
                Tailwind, Material UI, Bootstrap
              </li>
              <li>
                <span className="purple" style={{ fontWeight: 600 }}>
                  Mobile app development :{" "}
                </span>
                Flutter , React native
              </li>
            </ul>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/dalpat49"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="hhttps://www.linkedin.com/in/dalpat-singh-a72802204/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/dalpat_rathore09"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
